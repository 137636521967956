.menuOptions:hover {background-color: #FAD7A0;}

.archiveShelf:hover {background-color: skyblue;}

.archiveShelf {
  border: 1px solid black;
  background-color: #FFC04D;
  cursor: pointer;
  height: 100px;
  width: 200px;
}

.activeShelf {
  border: 1px solid black;
  background-color: dodgerblue;
  cursor: pointer;
  height: 100px;
  width: 200px;
}

tr:hover {background-color: #FAD7A0;}

tr {

  cursor: pointer; /* needed for iphone/ipad for click function --> https://stackoverflow.com/questions/3705937/document-click-not-working-correctly-on-iphone-jquery */

}

.phaseHeader {

  /* display: flex;
  align-items: center; */
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 3;

}

.jobHeader {

  /* display: flex;
  align-items: center; */
  position: sticky;
  top: 25px;
  background-color: white;
  z-index: 2;
}

.tableHeader th {

  position: sticky;
  top: 55px;
  background-color:  white;
  z-index: 1;
}

.jobHeader_alt {

  display: flex;
  align-items: center;
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
}

.tableHeader_alt th {

  position: sticky;
  top: 65px;
  background-color:  white;
  z-index: 1;
}

/* handles menus (archive) */
.jobHeader_alt2 {

  display: flex;
  align-items: center;
  position: sticky;
  top: 30px;
  background-color: white;
  z-index: 2;
}

.tableHeader_alt2 th {

  position: sticky;
  top: 95px;
  background-color:  white;
  z-index: 1;
}

.permissionTableHeader th {

  position: sticky;
  top: 0px;
  background-color:  white;
  /* z-index: 10; */
}

.flex {

  display: flex;
  align-items: center;

}
