.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}

/* .btn_rect-blue {

    display:inline-block;
    background: dodgerblue;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 40px;
    margin: 5px;
    padding: 8px 14px;
    color: #fff;
    cursor: pointer;

} */

.fadein {

    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */

}

/* .input {
  text-align: 'center';
}

.input input, select {

    border: 1px solid #d3d3d3;
    border-radius: 10px;
    display: inline-block;
    height: 40px;
    margin: 5px;

} */

.modal {

    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;
    background-color: rgba(0,0,0,0.4);

}

.modal-content {

    display: inline-block;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    background-color: #fefefe;
    width: 600px;
    margin-top: 10px;

}

.logoSize {
  display: inline-block;
  width: 300px;
}

@media screen and (max-width: 600px) {

  .modal-content {

    width: -moz-calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    width: -o-calc(100% - 20px);
    width: calc(100% - 20px);

  }

}

@media screen and (max-width: 300px) {

  .logoSize {

    width: -moz-calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    width: -o-calc(100% - 20px);
    width: calc(100% - 20px);

  }

}
