.signature-pad {

    border-radius: 10px;
    border: 1px solid gray;
    margin: 10px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    /* width: calc(100% - px); */
    height: 300px;

    background-color: #fff;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset; */

    padding: 16px;
}

.signature-pad::before,
.signature-pad::after {
    position: absolute;
    z-index: -1;
    content: "";
    width: 40%;
    height: 10px;
    bottom: 10px;
    background: transparent;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.signature-pad::before {
    left: 20px;
    -webkit-transform: skew(-3deg) rotate(-3deg);
          transform: skew(-3deg) rotate(-3deg);
}

.signature-pad::after {
    right: 20px;
    -webkit-transform: skew(3deg) rotate(3deg);
          transform: skew(3deg) rotate(3deg);
}

.signature-pad--full-name {
  border: 1px solid gray;
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  width: calc(100% - 30px);
  margin: 10px;
  font-size: 16px;
}

.signature-pad--body {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    /* border: 1px solid #f4f4f4; */
}

.signature-pad--body-x {
    text-align: left;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 20px;
    border: 0;
    border-bottom: 1px solid #000000;
    z-index: 1;
    user-select: none;
}

.signature-pad--body-note {
    text-align: center;
    position: absolute;
    left: 0;
    top: 60%;
    width: 100%;
    height: 20px;
    border: 0;
    z-index: 1;
    user-select: none;
}

.signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}
