.icons img {

    /* display: block; */
    height: 24px;
    width: 24px;

}

.iconBig {

    /* border: 1px solid red; */
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    outline: none;
    vertical-align: top;

}

.iconBiga i {

    font-size: 50px;

}

.iconBig img {

    /* border: 1px solid red; */
    display: inline-block;
    height: 50px;
    width: 50px;

}

.toolDrawMenu {

  border-radius: 10px;
  border: 1px solid #d3d3d3;
  background-color: #fefefe;

}

.canvasLine {

    height: 10px;
    width: -moz-calc(100% - 10px);
    width: -webkit-calc(100% - 10px);
    width: -o-calc(100% - 10px);
    width: calc(100% - 10px);

}

.styleLine {

    background: transparent;
    display: inline-block;
    font-famiy: Arial;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    width: 100%;
    margin: 5px 0px;
    text-align: center;
    outline: none;

}

.stylePreset {

    background: transparent;
    border: none;
    border-radius: 10px;
    display: inline-block;
    font-famiy: Arial;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    margin: 5px;
    text-align: center;
    outline: none;

}

.colorRainbow {

    background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

}

/* REFORMATTED ABOVE */

.testing {
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
}

.testingLayer {
  flex: 0 1 auto;
  border-right: 1px solid black;
  /* width: 50%; */
  resize: horizontal;
  overflow: auto;
}

#planscontainer {

  background: repeating-linear-gradient(-45deg, #FFD580, #FFD580 5px, white 5px, white 20px);
  flex: 1 1 auto;
  position: relative;
  /* left: 0;
  top: 0; */
  overflow: auto;
  height: 100%;
  width: 100%;
  
}

.canvas {

  border: 1px solid black;
  position: absolute;
  top: 0;
  left: 0;

}

#canvascontainer {

  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

}

/* #planscontainer {

  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  height: 100vh;
  width: 100vw;

}

.canvas {

  position: absolute;
  top: 0;
  left: 0;

}

#canvascontainer {

  width: 100%;
  height: 100%;
  overflow: auto;

} */

#planscoordsmsg {

  border: 2px solid black;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  font-size: 15px;
  background-color: white;

}

.plansCompass {

  color: gray;
  text-align: center;
  border: 2px solid black;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  font-size: 30px;
  transform-origin: center;
  border-radius: 50%;
  margin: 10px;
  background-color: white;
  width: 30px;
  height: 30px;
  line-height: 30px;

}

.plansCompassN {

  font-weight: bold;
  color: black;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  font-size: 12px;
  transform-origin: center;
  margin: 10px;
  width: 33px;
  height: 33px;
  line-height: 45px;

}

#plansZoom {

  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  font-size: 15px;

}

#plansSlider {

    /* display: block; */
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
    font-size: 15px;
    /* bring your own prefixes */
    transform: translate(-50%);

}

.fastDrawingContainter {

  /* display: block; */
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 4;
  font-size: 15px;
  /* bring your own prefixes */
  transform: translate(-50%);
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
}

.fastDrawingContents {
  position: relative;
  width: 200px;
  text-align: center;

}

/* .planscontainer {

    display: none;
    position: absolute;

} */

.plansDirection {

   text-align: center;
   display: block;
   position: fixed;
   bottom: 50px;
   left: 10px;
   z-index: 2;

}

.plansInfo {

    position: fixed;
    bottom: 10px;
    left: 50px;
    z-index: 2;

}

#plans_refresh {

    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 2;

}

.plansdraw {

    display: inline-block;
    /* border: none; */

}

.plans_legend {

    /* background: transparent; */

    border: 1px solid #d3d3d3;
    border-radius: 10px;
    background-color: dodgerblue; /*#fefefe;*/
    color: white;
    display: inline-block;
    font-famiy: Arial;
    font-size: 15px;
    font-weight: bold;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    /* transform: translate(-50%, -50%); */

}

.plans_legend_container {

    position: fixed;
    bottom: 10px;
    text-align: center;
    z-index: 2;

}

.plansdraw_style_header {

    display: flex;
    justify-content: space-between;

}

.plansdraw_style_header-text {

  margin: 5px;
  color: #d3d3d3;
  font-size: 20px;

}

#planslayercontainer {

    /* border: 1px solid red; */
    display: block;
    position: fixed;
    left: 10px;
    top: 48px;
    z-index: 3; /* 3 lets it sit above directions */

}

#planslayerexpand, #planslayerreduce {

    display: block;
    /* float: right; */
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

}

#planslayertable {

    position: relative;
    display: none;
    width: 150px;
    height: auto;
    max-height: 450px;
    /* overflow: auto; */
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    background-color: #fefefe;
    margin: 5px auto;

}

#planslayertablecontainer {

    position: relative;
    /* display: none; */
    height: auto;
    max-height: 450px;
    overflow: auto;
    /* border-radius: 10px;
    border: 1px solid #d3d3d3;     */
    background-color: #fefefe;
    /* margin: 5px auto; */

}

.plansmenu {

    position: relative;
    display: inline-block;

}

.planstype_index, .planstype_plan {

    display: none;

}

/* Slider (plan drawings scale) */

.slideContainerScale {
  width: 100%; /* Width of the outside container */  
  /* z-index: 4; */

  
  /* position: absolute;
  left: 50%;
  bottom: 0;  
  transform: translate(-50%); */

}