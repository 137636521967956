.nonActive {
  color: white;
  font-size: 20px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
}

.nonActive2 {
  color: black;
  font-size: 20px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
}

.active {
  color: dodgerblue;
  font-size: 20px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
}

.activeMenu {
  background-color: dodgerblue;
  color: white;
  font-size: 20px;
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
}
