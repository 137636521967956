/* .headerUser {
    flex: 0 0 100px; 
    overflow: auto;
    border: 1px solid gray; 
    width: 200;
    height: 200; 
    color: black;
    background-color: lightgray;
} */

/* .headerDate {
    border: 1px solid gray; 
    width: 200;
    height: 50; 
    color: black;
    background-color: lightgray;
} */

/* .headerTime {
    border: 1px solid gray;    
    height: 20; 
    color: black;
    background-color: lightgray;
} */

.headerUser {
    /* flex: 1 1 auto; */
    height: 50px;       
    text-align: left;    
    outline: 1px solid black;
    cursor: pointer;
    position: sticky;
    top: 0;    
    background: darkorchid;
    z-index: 5;
    font-weight: bold;
    color: white;
}

.headerTime {   
    flex: 1 1 auto;    
    height: 50px;
    text-align: left;    
    outline: 1px solid black;
    cursor: pointer;
    position: sticky;
    top: 0;
    background: mediumseagreen;
    z-index: 2;
    text-align: center;
    font-weight: bold;
    color: white;
}

.headerDay {   
    flex: 1 1 auto;    
    height: 50px;
    min-width: 40px;
    text-align: left;    
    outline: 1px solid black;
    cursor: pointer;
    /* position: sticky;
    top: 0;     */
    /* z-index: 2; */
    margin-bottom: 10px;    
}

.selectedDay {    
    background-color: gold;    
 }

.weekDay {    
    background-color: white;    
 }

 .weekend {    
    background-color: rgb(143, 216, 230);
 }

.rowUser {
    flex: 1 1 auto;
    min-height: 50px;       
    text-align: left;    
    outline: 1px solid lightgray;
    cursor: pointer;
    position: sticky;    
    left: 0;
    background-color: white;    
}

.rowTime {   
    flex: 1 1 auto;
    width: 0px;
    min-height: 50px;
    min-width: 50px;
    text-align: left;
    /* padding-right: 5; */
    outline: 1px solid lightgray;
    cursor: pointer
}

.requestedWork {
    position: absolute; 
    top: 0; 
    left: 0; 
    outline: 2px solid black;
    font-size: smaller;
    text-wrap: wrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    cursor: pointer;
    z-index: 1;
    border-radius: 5px;
 }

 .unassigned {    
    background: white; 
 }

 /* background: repeating-linear-gradient(-45deg, tomato, tomato 5px, white 5px, white 20px); */

 .confirmed {    
    background-color: dodgerblue;    
 }

 .pending {    
    background-color: orange;
 }

 .cancelled {    
    background-color: red;
    opacity: 0.3;
 }

 .cancelled {    
    background-color: red;
    opacity: 0.3;
 }

 .personal {        
    background-color: lightgreen;    
 }

 .flexContainer {
    /* justify-content: center; */
    /* align-items: center; */
    
    display: flex; 
    flex-direction: column;
    align-content: flex-start;
    width: 100%; 
    height: 100%;
    
 }

 .UnassignedHeight {
    background-color: lightyellow;
 }